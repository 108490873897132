import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Configuration from './configuration'

const Image = styled.img`
  ${css({
    px: 1,
    width: '100%',
    height: 'auto',
    maxHeight: '20px',
  })}
`
export function CurrencyProviderImages() {
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviderImageUrls(state.configuration)
  )

  return (
    <Common.Box
      alignItems="center"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      p="1"
      width="100%"
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="lavender"
    >
      {paymentProviders?.map(item => (
        <Common.Box
          key={item?.image?.src}
          height="20px"
          maxWidth="170px"
          alignItems="center"
          display="flex"
          justifyContent="center"
          my={0}
        >
          <Image
            src={item?.image?.url}
            alt={item.name}
            height="20"
            width="115"
          />
        </Common.Box>
      ))}
    </Common.Box>
  )
}
