import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import { Button } from '../button'
import { LoginBox } from '../login-box'
import { useMenuQueries } from '../use-menu-queries'

const HeaderText = styled.h3`
  font-feature-settings: 'clig' off, 'liga' off;
  leading-trim: both;
  text-edge: cap;
  background: linear-gradient(143deg, #ffcd01 16.28%, #dc6a09 92.66%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Logotype = styled('div')`
  ${css({
    width: '60px',
    height: '60px',
    backgroundImage: 'url(/images/logo-icon.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: 'auto',
  })};
`

export function LandingBanner() {
  const translate = Herz.I18n.useTranslate()
  const [returningPlayer] = Cookies.useCookie('returning_player')

  const { registerQuery } = useMenuQueries()

  return (
    <Common.Box
      backgroundImage={`radial-gradient(69.88% 95.44% at 50% 50%, rgba(38, 12, 77, 0.00) 0%, #130830 100%), url(${translate(
        'landing-page.hero.background'
      )})`}
      border={['none', '1px solid rgba(255, 255, 255, 0.10)']}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      py={[null, 1, 2]}
      maxWidth={['100%', null, Constants.ContentMaxWidth]}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      color="white"
      borderRadius={[null, '30px']}
      position="relative"
    >
      <Common.Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={['90%', '345px']}
        backgroundColor="blueberry"
        borderRadius="16px"
        px="20px"
        py={[3, '20px']}
        my={['40px', '0px']}
      >
        <Common.Box display="flex" flexDirection="column" textAlign="center">
          {returningPlayer ? <Logotype /> : null}
          <Common.Box
            fontSize="22px"
            fontWeight="700"
            lineHeight="32px"
            mb={returningPlayer ? '0px' : '22px'}
            mt={returningPlayer ? '8px' : '0px'}
          >
            {translate('login-banner.welcome')}
            <Common.Box
              as="span"
              fontSize="22px"
              fontWeight="700"
              lineHeight="32px"
              ml="5px"
            >
              {returningPlayer
                ? translate('login-banner.back')
                : translate('login-banner.offer')}
            </Common.Box>
          </Common.Box>
        </Common.Box>
        {returningPlayer ? (
          <>
            <Common.Box
              width="100%"
              height="120px"
              mb="16px"
              backgroundImage={`url(${translate('landing-banner.image')})`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              borderRadius="10px"
            />
            <LoginBox />
          </>
        ) : (
          <>
            <Common.Box
              display="flex"
              position="relative"
              flexDirection="column"
              justifyContent="flex-end"
              width="100%"
              height="180px"
              backgroundImage={`radial-gradient(136.21% 94.14% at 52.17% 3.18%, rgba(0, 82, 180, 0.00) 0%, #26125D 84.82%), url(${translate(
                'landing-banner.image'
              )})`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              borderRadius="10px"
            >
              <Common.Box
                display="flex"
                position="absolute"
                bottom="-30px"
                justifyContent="flex-start"
                flexDirection="column"
                width="100%"
                mt={[1, '0px']}
              >
                <Common.Box mx="auto" mb={0}>
                  <Common.Box
                    fontSize="13px"
                    lineHeight="18px"
                    textAlign="center"
                    fontWeight="300"
                    mb="10px"
                  >
                    {translate('landing-page.title')}
                  </Common.Box>

                  <HeaderText style={{ fontSize: '55px', fontWeight: '800' }}>
                    {translate('landing-page.money-bonus.up-to')}
                  </HeaderText>
                </Common.Box>
              </Common.Box>
            </Common.Box>
            <Common.Box
              fontSize="36px"
              fontWeight="700"
              py="30px"
              textAlign="center"
            >
              {translate('landing-page.free-spins.game')}
            </Common.Box>
            <Common.Box
              display="flex"
              flexDirection={['column', 'row']}
              justifyContent="flex-start"
              width="100%"
            >
              <Button
                as={ReactRouter.Link}
                to={`?${registerQuery}`}
                style={{ display: 'block', textAlign: 'center' }}
                data-testid="landingpage.register-button"
                stretch
                variant="primary"
                fontSize={3}
              >
                <Common.Box py="2px">
                  {translate('landing-page.get-started')}
                </Common.Box>
              </Button>
            </Common.Box>
          </>
        )}
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'landing-banner.image',
    'landing-page.free-spins.game',
    'landing-page.free',
    'landing-page.spins',
    'landing-page.get-started',
    'landing-page.hero.background',
    'landing-page.hero.flag',
    'landing-page.money-bonus.up-to',
    'landing-page.subheader',
    'landing-page.title',
    'login-banner.back',
    'login-banner.offer',
    'login-banner.welcome',
    'main-menu.register',
  ],
  LandingBanner
)
